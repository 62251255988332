<div class="modal fade" tabindex="-1" role="dialog" aria-modal="true" aria-labelledby="passHistoryTitle">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h2 class="modal-title" id="passHistoryTitle">{{'passwordHistory' | i18n}}</h2>
                <button type="button" class="close" data-dismiss="modal" appA11yTitle="{{'close' | i18n}}">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <ul class="list-group list-group-flush" *ngIf="history.length">
                <li class="list-group-item d-flex" *ngFor="let h of history">
                    <div class="password-row">
                        <div class="text-monospace password-wrapper" [innerHTML]="h.password | colorPassword"
                            appSelectCopy></div>
                        <small class="text-muted">{{h.date | date:'medium'}}</small>
                    </div>
                    <div class="ml-auto">
                        <button class="btn btn-link" appA11yTitle="{{'copyPassword' | i18n}}"
                            (click)="copy(h.password)">
                            <i class="fa fa-lg fa-clipboard" aria-hidden="true"></i>
                        </button>
                    </div>
                </li>
            </ul>
            <div class="modal-body" *ngIf="!history.length">
                {{'noPasswordsInList' | i18n}}
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-outline-secondary" data-dismiss="modal">
                    {{'close' | i18n}}
                </button>
                <div class="ml-auto">
                    <button type="button" (click)="clear()" class="btn btn-outline-danger"
                        appA11yTitle="{{'clear' | i18n}}">
                        <i class="fa fa-trash-o fa-lg fa-fw" aria-hidden="true"></i>
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>
