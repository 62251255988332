<app-callout type="warning">{{'loggedOutWarning' | i18n}}</app-callout>
<app-callout type="info" *ngIf="enforcedPolicyOptions">
    {{'masterPasswordPolicyInEffect' | i18n}}
    <ul class="mb-0">
        <li *ngIf="enforcedPolicyOptions?.minComplexity > 0">
            {{'policyInEffectMinComplexity' | i18n : getPasswordScoreAlertDisplay()}}
        </li>
        <li *ngIf="enforcedPolicyOptions?.minLength > 0">
            {{'policyInEffectMinLength' | i18n : enforcedPolicyOptions?.minLength.toString()}}
        </li>
        <li *ngIf="enforcedPolicyOptions?.requireUpper">{{'policyInEffectUppercase' | i18n}}</li>
        <li *ngIf="enforcedPolicyOptions?.requireLower">{{'policyInEffectLowercase' | i18n}}</li>
        <li *ngIf="enforcedPolicyOptions?.requireNumbers">{{'policyInEffectNumbers' | i18n}}</li>
        <li *ngIf="enforcedPolicyOptions?.requireSpecial">{{'policyInEffectSpecial' | i18n : '!@#$%^&*'}}</li>
    </ul>
</app-callout>

<form #form (ngSubmit)="submit()" [appApiAction]="formPromise" ngNativeValidate autocomplete="off">
    <div class="row">
        <div class="col-6">
            <div class="form-group">
                <label for="currentMasterPassword">{{'currentMasterPass' | i18n}}</label>
                <input id="currentMasterPassword" type="password" name="MasterPasswordHash" class="form-control"
                    [(ngModel)]="currentMasterPassword" required appInputVerbatim>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-6">
            <div class="form-group">
                <label for="newMasterPassword">{{'newMasterPass' | i18n}}</label>
                <input id="newMasterPassword" type="password" name="NewMasterPasswordHash" class="form-control mb-1"
                    [(ngModel)]="newMasterPassword" (input)="updatePasswordStrength()" required appInputVerbatim
                    autocomplete="new-password">
                <app-password-strength [score]="masterPasswordScore" [showText]="true"></app-password-strength>
            </div>
        </div>
        <div class="col-6">
            <div class="form-group">
                <label for="confirmNewMasterPassword">{{'confirmNewMasterPass' | i18n}}</label>
                <input id="confirmNewMasterPassword" type="password" name="ConfirmNewMasterPasswordHash"
                    class="form-control" [(ngModel)]="confirmNewMasterPassword" required appInputVerbatim
                    autocomplete="new-password">
            </div>
        </div>
    </div>
    <div class="form-group">
        <div class="form-check">
            <input class="form-check-input" type="checkbox" id="rotateEncKey" name="RotateEncKey"
                [(ngModel)]="rotateEncKey" (change)="rotateEncKeyClicked()">
            <label class="form-check-label" for="rotateEncKey">
                {{'rotateAccountEncKey' | i18n}}
            </label>
            <a href="https://help.bitwarden.com/article/change-your-master-password/#rotating-your-accounts-encryption-key"
                target="_blank" rel="noopener" appA11yTitle="{{'learnMore' | i18n}}">
                <i class="fa fa-question-circle-o" aria-hidden="true"></i>
            </a>
        </div>
    </div>
    <button type="submit" class="btn btn-primary btn-submit" [disabled]="form.loading">
        <i class="fa fa-spinner fa-spin" title="{{'loading' | i18n}}" aria-hidden="true"></i>
        <span>{{'changeMasterPassword' | i18n}}</span>
    </button>
</form>
