<div class="page-header d-flex">
    <h1>{{'policies' | i18n}}</h1>
</div>
<ng-container *ngIf="loading">
    <i class="fa fa-spinner fa-spin text-muted" title="{{'loading' | i18n}}" aria-hidden="true"></i>
    <span class="sr-only">{{'loading' | i18n}}</span>
</ng-container>
<table class="table table-hover table-list" *ngIf="!loading">
    <tbody>
        <tr *ngFor="let p of policies">
            <td>
                <a href="#" appStopClick (click)="edit(p)">{{p.name}}</a>
                <span class="badge badge-success" *ngIf="p.enabled">{{'enabled' | i18n}}</span>
                <small class="text-muted d-block">{{p.description}}</small>
            </td>
        </tr>
    </tbody>
</table>
<ng-template #editTemplate></ng-template>
