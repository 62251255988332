<div class="container page-content">
    <div class="row">
        <div class="col-3">
            <div class="card mb-4">
                <div class="card-header">{{'tools' | i18n}}</div>
                <div class="list-group list-group-flush">
                    <a routerLink="import" class="list-group-item" routerLinkActive="active">
                        {{'importData' | i18n}}
                    </a>
                    <a routerLink="export" class="list-group-item" routerLinkActive="active">
                        {{'exportVault' | i18n}}
                    </a>
                </div>
            </div>
            <div class="card">
                <div class="card-header d-flex">
                    {{'reports' | i18n}}
                    <div class="ml-auto">
                        <a href="#" appStopClick class="badge badge-primary" *ngIf="!accessReports"
                            (click)="upgradeOrganization()">
                            {{'upgrade' | i18n}}
                        </a>
                    </div>
                </div>
                <div class="list-group list-group-flush">
                    <a routerLink="exposed-passwords-report" class="list-group-item" routerLinkActive="active">
                        {{'exposedPasswordsReport' | i18n}}
                    </a>
                    <a routerLink="reused-passwords-report" class="list-group-item" routerLinkActive="active">
                        {{'reusedPasswordsReport' | i18n}}
                    </a>
                    <a routerLink="weak-passwords-report" class="list-group-item" routerLinkActive="active">
                        {{'weakPasswordsReport' | i18n}}
                    </a>
                    <a routerLink="unsecured-websites-report" class="list-group-item" routerLinkActive="active">
                        {{'unsecuredWebsitesReport' | i18n}}
                    </a>
                    <a routerLink="inactive-two-factor-report" class="list-group-item" routerLinkActive="active">
                        {{'inactive2faReport' | i18n}}
                    </a>
                </div>
            </div>
        </div>
        <div class="col-9">
            <router-outlet></router-outlet>
        </div>
    </div>
</div>
